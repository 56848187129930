#time-deltas td:nth-child(-n + 2) {
    width: 30%;
}

#time-deltas td:nth-child(4) {
    width: 10%;
}

#time-deltas .goal-met {
    color: green;
}

#time-deltas .goal-not-met {
    color: red;
}